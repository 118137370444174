/**
 * @module Fuel HOC
 * @description Central source providers, avoids having multiple nested providers.
 */
import {
  createContext, useContext, useEffect, useState
} from 'react';
import { ErrorBoundary, CohesionContext } from '@red-digital/bricks';
import ErrorBoundaryComponent from '@/blocks/ErrorBoundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'; // ReCaptcha Provider used to generate the token we pass in the 'frontierSiteDetailPredictive' cookie
import { useRouter } from 'next/router';
import {
  isCartHeavy, isPhoneHeavy, isResumeQuote, updateOLVersion
} from './utils/feature-flagging';
import useCartState from '@/hooks/useCartState';
import { DEFAULT_OPEN_HOURS } from '@/js/constants/landingPage';

/**
 * @function FuelContext
 * @description
 */
export const FuelContext = createContext();

/**
 * @function withFuel
 * @description
 */
export const withFuel = ( { App } ) => {
  const FuelProviderHOC = ( props ) => {
    const router = useRouter();
    const { monarchResponse } = useContext( CohesionContext );
    const { clickThrough } = useCartState();

    const [ monarchProduct, setMonarchProduct ] = useState( 'fiber-500m' );
    const [ monarchCartHeavy, setMonarchCartHeavy ] = useState( false );
    const [ monarchPhoneHeavy, setMonarchPhoneHeavy ] = useState( false );
    const [ monarchResumeQuote, setMonarchResumeQuote ] = useState( false );
    const [ monarchOLVersion, setMonarchOLVersion ] = useState( 'v2' );
    const [ monarchOpenHours, setMonarchOpenHours ] = useState( [] );

    const [ formInputted, setFormInputted ] = useState( false );

    useEffect( () => {
      // Return if we're still waiting for the monarch response OR if in storybook
      if ( ( ! monarchResponse ) || process.env.environment === 'storybook' ) return;

      const {
        determineProduct, cartHeavy, phoneHeavy, determineResumeQuote, determineOLVersion, attendHours
      } = monarchResponse;

      if ( typeof determineProduct === 'string' ) setMonarchProduct( determineProduct );

      if ( isCartHeavy( cartHeavy, router.query ) ) setMonarchCartHeavy( true );

      if ( isPhoneHeavy( phoneHeavy, router.query ) ) setMonarchPhoneHeavy( true );

      if ( isResumeQuote( determineResumeQuote ) ) setMonarchResumeQuote( true );

      if ( updateOLVersion( determineOLVersion ) ) setMonarchOLVersion( determineOLVersion );

      if ( attendHours?.data && Array.isArray( attendHours?.data ) ) {
        setMonarchOpenHours( attendHours.data );
      } else {
        setMonarchOpenHours( DEFAULT_OPEN_HOURS );
      }
    }, [ monarchResponse, router.query ] );

    return (
    <FuelContext.Provider value={ {
      monarchProduct,
      cartHeavy: monarchCartHeavy,
      phoneHeavy: monarchPhoneHeavy,
      clickThrough,
      resumeQuote: monarchResumeQuote,
      olVersion: monarchOLVersion,
      formInputted,
      setFormInputted,
      openHours: monarchOpenHours
    } }>
      <ErrorBoundary production={ process.env.environment === 'production' } component={ ErrorBoundaryComponent }>
        <GoogleReCaptchaProvider useEnterprise reCaptchaKey={ `${ process.env.reCaptchaSiteKey }` } scriptProps={ { defer: true } }>
          <App { ...props } />
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
    </FuelContext.Provider>
    );
  };

  return FuelProviderHOC;
};

export default withFuel;
