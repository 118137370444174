// DO NOT EDIT THIS FILE. THIS FILE IS GENERATED IN NEXT.CONFIG.JS
import { lazy } from "react";

const listOfPreampAssets = {
  ['Banner | Address Check | Single Line']: lazy(() => import('./assets/Banner | Address Check | Single Line')),
  ['Banner | Address Check | Sticky | White']: lazy(() => import('./assets/Banner | Address Check | Sticky | White')),
  ['Banner | Address Check | White']: lazy(() => import('./assets/Banner | Address Check | White')),
  ['Banner | CTA | Neighborhood']: lazy(() => import('./assets/Banner | CTA | Neighborhood')),
  ['Banner | Countdown | Editable']: lazy(() => import('./assets/Banner | Countdown | Editable')),
  ['Banner | Days Countdown | Editable']: lazy(() => import('./assets/Banner | Days Countdown | Editable')),
  ['Banner | LTO (Bricks) | Editable']: lazy(() => import('./assets/Banner | LTO (Bricks) | Editable')),
  ['Banner | LTO | Editable']: lazy(() => import('./assets/Banner | LTO | Editable')),
  ['Banner | List | Expansion']: lazy(() => import('./assets/Banner | List | Expansion')),
  ['Hero | Address Check Horizon Lines | Editable']: lazy(() => import('./assets/Hero | Address Check Horizon Lines | Editable')),
  ['Hero | Address Check | 2G']: lazy(() => import('./assets/Hero | Address Check | 2G')),
  ['Hero | Address Check | 5G']: lazy(() => import('./assets/Hero | Address Check | 5G')),
  ['Hero | Address Check | Connecticut - Lighthouse']: lazy(() => import('./assets/Hero | Address Check | Connecticut - Lighthouse')),
  ['Hero | Address Check | Connecticut - Night Skyline']: lazy(() => import('./assets/Hero | Address Check | Connecticut - Night Skyline')),
  ['Hero | Address Check | Connecticut - Night Skyline Video']: lazy(() => import('./assets/Hero | Address Check | Connecticut - Night Skyline Video')),
  ['Hero | Address Check | Editable']: lazy(() => import('./assets/Hero | Address Check | Editable')),
  ['Hero | Address Check | Eero']: lazy(() => import('./assets/Hero | Address Check | Eero')),
  ['Hero | Address Check | Pre Sunday Ticket']: lazy(() => import('./assets/Hero | Address Check | Pre Sunday Ticket')),
  ['Hero | Address Check | Single Line']: lazy(() => import('./assets/Hero | Address Check | Single Line')),
  ['Hero | Address Check | Sunday Ticket - Editable']: lazy(() => import('./assets/Hero | Address Check | Sunday Ticket - Editable')),
  ['Hero | Address Check | Upload Speeds Campaign']: lazy(() => import('./assets/Hero | Address Check | Upload Speeds Campaign')),
  ['Hero | Address Check | YTTV']: lazy(() => import('./assets/Hero | Address Check | YTTV')),
  ['Hero | Address Check | YTTV Summer Deal']: lazy(() => import('./assets/Hero | Address Check | YTTV Summer Deal')),
  ['Hero | BAU (Bricks) | Editable']: lazy(() => import('./assets/Hero | BAU (Bricks) | Editable')),
  ['Hero | Carousel']: lazy(() => import('./assets/Hero | Carousel')),
  ['Hero | Carousel - 200mb + Wi-Fi 7']: lazy(() => import('./assets/Hero | Carousel - 200mb + Wi-Fi 7')),
  ['Hero | Carousel | Redesign - Editable']: lazy(() => import('./assets/Hero | Carousel | Redesign - Editable')),
  ['Hero | Carousel | Tabs']: lazy(() => import('./assets/Hero | Carousel | Tabs')),
  ['Hero | Carousel | Tabs - 2G']: lazy(() => import('./assets/Hero | Carousel | Tabs - 2G')),
  ['Hero | Carousel | Tabs - 2G VRC']: lazy(() => import('./assets/Hero | Carousel | Tabs - 2G VRC')),
  ['Hero | Carousel | Tabs - CNET Badge']: lazy(() => import('./assets/Hero | Carousel | Tabs - CNET Badge')),
  ['Hero | Carousel | Tabs - CNET Badge Summer Deal']: lazy(() => import('./assets/Hero | Carousel | Tabs - CNET Badge Summer Deal')),
  ['Hero | Carousel | Tabs - Expansion']: lazy(() => import('./assets/Hero | Carousel | Tabs - Expansion')),
  ['Hero | Carousel | Tabs - Summer Deal']: lazy(() => import('./assets/Hero | Carousel | Tabs - Summer Deal')),
  ['Hero | Carousel | Tabs - Sunday Ticket - Editable']: lazy(() => import('./assets/Hero | Carousel | Tabs - Sunday Ticket - Editable')),
  ['Hero | Content Only | Editable']: lazy(() => import('./assets/Hero | Content Only | Editable')),
  ['Hero | Content Only | Expansion']: lazy(() => import('./assets/Hero | Content Only | Expansion')),
  ['Hero | Content Only | Football Campaign']: lazy(() => import('./assets/Hero | Content Only | Football Campaign')),
  ['Hero | Content Only | Future is Fiber - Blue CTA']: lazy(() => import('./assets/Hero | Content Only | Future is Fiber - Blue CTA')),
  ['Hero | Content Only | Movers']: lazy(() => import('./assets/Hero | Content Only | Movers')),
  ['Hero | Content Only | Wi-Fi 7']: lazy(() => import('./assets/Hero | Content Only | Wi-Fi 7')),
  ['Hero | Editable']: lazy(() => import('./assets/Hero | Editable')),
  ['Hero | Price Point Split | P72']: lazy(() => import('./assets/Hero | Price Point Split | P72')),
  ['Hero | Price Point Split | P72 Editable']: lazy(() => import('./assets/Hero | Price Point Split | P72 Editable')),
  ['Hero | Price Point | 2G']: lazy(() => import('./assets/Hero | Price Point | 2G')),
  ['Hero | Price Point | Copper']: lazy(() => import('./assets/Hero | Price Point | Copper')),
  ['Hero | Price Point | Dynamic']: lazy(() => import('./assets/Hero | Price Point | Dynamic')),
  ['Hero | Price Point | Dynamic - Ultrafast Language']: lazy(() => import('./assets/Hero | Price Point | Dynamic - Ultrafast Language')),
  ['Hero | Price Point | Editable']: lazy(() => import('./assets/Hero | Price Point | Editable')),
  ['Hero | Price Point | Expansion']: lazy(() => import('./assets/Hero | Price Point | Expansion')),
  ['Hero | Price Point | Los Angeles']: lazy(() => import('./assets/Hero | Price Point | Los Angeles')),
  ['Hero | Price Point | Movers']: lazy(() => import('./assets/Hero | Price Point | Movers')),
  ['Hero | Price Point | Tampa']: lazy(() => import('./assets/Hero | Price Point | Tampa')),
  ['Hero | Price Point | Tiered']: lazy(() => import('./assets/Hero | Price Point | Tiered')),
  ['Hero | Price Point | Tiered - 5 Gig']: lazy(() => import('./assets/Hero | Price Point | Tiered - 5 Gig')),
  ['Hero | Price Point | Tiered - 5 Gig Movers']: lazy(() => import('./assets/Hero | Price Point | Tiered - 5 Gig Movers')),
  ['Hero | Price Point | Tiered - 5 Gig P72']: lazy(() => import('./assets/Hero | Price Point | Tiered - 5 Gig P72')),
  ['Hero | Price Point | Tiered - Editable']: lazy(() => import('./assets/Hero | Price Point | Tiered - Editable')),
  ['Hero | Price Point | Tiered | Los Angeles']: lazy(() => import('./assets/Hero | Price Point | Tiered | Los Angeles')),
  ['Hero | Price Point | Tiered | P72']: lazy(() => import('./assets/Hero | Price Point | Tiered | P72')),
  ['Hero | Vertical Hero (Bricks) | Editable']: lazy(() => import('./assets/Hero | Vertical Hero (Bricks) | Editable')),
  ['Modal | GDT']: lazy(() => import('./assets/Modal | GDT')),
  ['Modal | Return Visitor Refresh Dual CTA']: lazy(() => import('./assets/Modal | Return Visitor Refresh Dual CTA')),
  ['Modal | Return Visitor Refresh Image']: lazy(() => import('./assets/Modal | Return Visitor Refresh Image')),
  ['Section | Banner Headings | Editable']: lazy(() => import('./assets/Section | Banner Headings | Editable')),
  ['Section | Banner | Movers']: lazy(() => import('./assets/Section | Banner | Movers')),
  ['Section | Banner | YTTV']: lazy(() => import('./assets/Section | Banner | YTTV')),
  ['Section | Bento (Bricks) | Editable']: lazy(() => import('./assets/Section | Bento (Bricks) | Editable')),
  ['Section | CNET | Editable']: lazy(() => import('./assets/Section | CNET | Editable')),
  ['Section | Cabinet']: lazy(() => import('./assets/Section | Cabinet')),
  ['Section | Cabinet | Single - Editable']: lazy(() => import('./assets/Section | Cabinet | Single - Editable')),
  ['Section | Cards Carousel (Bricks) - Editable']: lazy(() => import('./assets/Section | Cards Carousel (Bricks) - Editable')),
  ['Section | Carousel Cards | P72']: lazy(() => import('./assets/Section | Carousel Cards | P72')),
  ['Section | Carousel | 2G']: lazy(() => import('./assets/Section | Carousel | 2G')),
  ['Section | Carousel | 2G Thumb']: lazy(() => import('./assets/Section | Carousel | 2G Thumb')),
  ['Section | Carousel | Cards Slim - Editable']: lazy(() => import('./assets/Section | Carousel | Cards Slim - Editable')),
  ['Section | Carousel | Fiber Merch Thumb']: lazy(() => import('./assets/Section | Carousel | Fiber Merch Thumb')),
  ['Section | Carousel | Movers']: lazy(() => import('./assets/Section | Carousel | Movers')),
  ['Section | Carousel | Movers Headline Thumb']: lazy(() => import('./assets/Section | Carousel | Movers Headline Thumb')),
  ['Section | Carousel | Movers Headline Thumb - YTTV Summer Deal']: lazy(() => import('./assets/Section | Carousel | Movers Headline Thumb - YTTV Summer Deal')),
  ['Section | Carousel | Movers Thumb']: lazy(() => import('./assets/Section | Carousel | Movers Thumb')),
  ['Section | Carousel | Movers Thumb - YTTV Summer Deal']: lazy(() => import('./assets/Section | Carousel | Movers Thumb - YTTV Summer Deal')),
  ['Section | Carousel | P72']: lazy(() => import('./assets/Section | Carousel | P72')),
  ['Section | Carousel | P72 Thumb']: lazy(() => import('./assets/Section | Carousel | P72 Thumb')),
  ['Section | Carousel | P72 Thumb Uncable Language']: lazy(() => import('./assets/Section | Carousel | P72 Thumb Uncable Language')),
  ['Section | Carousel | P72 Thumb Uncable Language - Refreshed Imagery']: lazy(() => import('./assets/Section | Carousel | P72 Thumb Uncable Language - Refreshed Imagery')),
  ['Section | Carousel | Slim Editable']: lazy(() => import('./assets/Section | Carousel | Slim Editable')),
  ['Section | Carousel | Thumb - NFL Movers']: lazy(() => import('./assets/Section | Carousel | Thumb - NFL Movers')),
  ['Section | Carousel | Thumb CDAPI']: lazy(() => import('./assets/Section | Carousel | Thumb CDAPI')),
  ['Section | Carousel | Thumb Expansion']: lazy(() => import('./assets/Section | Carousel | Thumb Expansion')),
  ['Section | Column Content | 5G']: lazy(() => import('./assets/Section | Column Content | 5G')),
  ['Section | Column Tiles (Bricks) | Editable']: lazy(() => import('./assets/Section | Column Tiles (Bricks) | Editable')),
  ['Section | Column Tiles (Bricks) | Reviews']: lazy(() => import('./assets/Section | Column Tiles (Bricks) | Reviews')),
  ['Section | Column Tiles | Copper']: lazy(() => import('./assets/Section | Column Tiles | Copper')),
  ['Section | Column Tiles | Editable']: lazy(() => import('./assets/Section | Column Tiles | Editable')),
  ['Section | Column Tiles | Eero']: lazy(() => import('./assets/Section | Column Tiles | Eero')),
  ['Section | Column Tiles | Fiber']: lazy(() => import('./assets/Section | Column Tiles | Fiber')),
  ['Section | Column Tiles | Movers']: lazy(() => import('./assets/Section | Column Tiles | Movers')),
  ['Section | ColumnContent | Upload Speeds Campaign']: lazy(() => import('./assets/Section | ColumnContent | Upload Speeds Campaign')),
  ['Section | Columns | Fiber']: lazy(() => import('./assets/Section | Columns | Fiber')),
  ['Section | Columns | Fiber Dark']: lazy(() => import('./assets/Section | Columns | Fiber Dark')),
  ['Section | Columns | Movers']: lazy(() => import('./assets/Section | Columns | Movers')),
  ['Section | Columns | YTTV']: lazy(() => import('./assets/Section | Columns | YTTV')),
  ['Section | Columns | YTTV Summer Deal']: lazy(() => import('./assets/Section | Columns | YTTV Summer Deal')),
  ['Section | Comparison Grid V2']: lazy(() => import('./assets/Section | Comparison Grid V2')),
  ['Section | Dynamic Speed Graph | Editable']: lazy(() => import('./assets/Section | Dynamic Speed Graph | Editable')),
  ['Section | Dynamic Speed Graph | Editable - ToBeArchieved']: lazy(() => import('./assets/Section | Dynamic Speed Graph | Editable - ToBeArchieved')),
  ['Section | Email Capture - Editable']: lazy(() => import('./assets/Section | Email Capture - Editable')),
  ['Section | Empty']: lazy(() => import('./assets/Section | Empty')),
  ['Section | FAQ (Bricks)']: lazy(() => import('./assets/Section | FAQ (Bricks)')),
  ['Section | FAQ | Copper']: lazy(() => import('./assets/Section | FAQ | Copper')),
  ['Section | FAQ | Movers']: lazy(() => import('./assets/Section | FAQ | Movers')),
  ['Section | FAQ | Plans Pricing']: lazy(() => import('./assets/Section | FAQ | Plans Pricing')),
  ['Section | GDT | CTA']: lazy(() => import('./assets/Section | GDT | CTA')),
  ['Section | GDT | Editable']: lazy(() => import('./assets/Section | GDT | Editable')),
  ['Section | GDT | QUIZ']: lazy(() => import('./assets/Section | GDT | QUIZ')),
  ['Section | Large Banner (Bricks) | Editable']: lazy(() => import('./assets/Section | Large Banner (Bricks) | Editable')),
  ['Section | Offers - Editable']: lazy(() => import('./assets/Section | Offers - Editable')),
  ['Section | Plan Features']: lazy(() => import('./assets/Section | Plan Features')),
  ['Section | Price Point | 5 Gig']: lazy(() => import('./assets/Section | Price Point | 5 Gig')),
  ['Section | Price Point | Copper']: lazy(() => import('./assets/Section | Price Point | Copper')),
  ['Section | Price Point | Editable']: lazy(() => import('./assets/Section | Price Point | Editable')),
  ['Section | Price Point | Headline Lead - Blue CTA']: lazy(() => import('./assets/Section | Price Point | Headline Lead - Blue CTA')),
  ['Section | Price Point | Movers']: lazy(() => import('./assets/Section | Price Point | Movers')),
  ['Section | Price Point | P72']: lazy(() => import('./assets/Section | Price Point | P72')),
  ['Section | Product Cards']: lazy(() => import('./assets/Section | Product Cards')),
  ['Section | Product Cards (Bricks) | Editable']: lazy(() => import('./assets/Section | Product Cards (Bricks) | Editable')),
  ['Section | Product Cards | CTA']: lazy(() => import('./assets/Section | Product Cards | CTA')),
  ['Section | Product Cards | CTA GDT']: lazy(() => import('./assets/Section | Product Cards | CTA GDT')),
  ['Section | Product Cards | Editable']: lazy(() => import('./assets/Section | Product Cards | Editable')),
  ['Section | Product Cards | Expansion - Editable']: lazy(() => import('./assets/Section | Product Cards | Expansion - Editable')),
  ['Section | Product Cards | Full Width Carousel']: lazy(() => import('./assets/Section | Product Cards | Full Width Carousel')),
  ['Section | Product Cards | Speed Visuals']: lazy(() => import('./assets/Section | Product Cards | Speed Visuals')),
  ['Section | Product Cards | Speed Visuals GDT']: lazy(() => import('./assets/Section | Product Cards | Speed Visuals GDT')),
  ['Section | Product Cards | SpeedVantage']: lazy(() => import('./assets/Section | Product Cards | SpeedVantage')),
  ['Section | Product Cards | Upload Speeds Campaign']: lazy(() => import('./assets/Section | Product Cards | Upload Speeds Campaign')),
  ['Section | Speed Bar Graph']: lazy(() => import('./assets/Section | Speed Bar Graph')),
  ['Section | Speed Graphs | Upload Speeds Campaign']: lazy(() => import('./assets/Section | Speed Graphs | Upload Speeds Campaign')),
  ['Section | Speed Visuals (Bricks) | Editable']: lazy(() => import('./assets/Section | Speed Visuals (Bricks) | Editable')),
  ['Section | Speedometer']: lazy(() => import('./assets/Section | Speedometer')),
  ['Section | Split Module (Bricks) | Editable']: lazy(() => import('./assets/Section | Split Module (Bricks) | Editable')),
  ['Section | Split Section Eero | Editable']: lazy(() => import('./assets/Section | Split Section Eero | Editable')),
  ['Section | Split Section | ACP | Mazama']: lazy(() => import('./assets/Section | Split Section | ACP | Mazama')),
  ['Section | Split Section | Connect Your World']: lazy(() => import('./assets/Section | Split Section | Connect Your World')),
  ['Section | Split Section | Do More + Built for Gaming']: lazy(() => import('./assets/Section | Split Section | Do More + Built for Gaming')),
  ['Section | Split Section | Editable']: lazy(() => import('./assets/Section | Split Section | Editable')),
  ['Section | Split Section | Eero']: lazy(() => import('./assets/Section | Split Section | Eero')),
  ['Section | Split Section | Eero + Frontier']: lazy(() => import('./assets/Section | Split Section | Eero + Frontier')),
  ['Section | Split Section | Eero Fiber']: lazy(() => import('./assets/Section | Split Section | Eero Fiber')),
  ['Section | Split Section | Embrace Internet']: lazy(() => import('./assets/Section | Split Section | Embrace Internet')),
  ['Section | Split Section | Expansion']: lazy(() => import('./assets/Section | Split Section | Expansion')),
  ['Section | Split Section | Future Proof Fiber - Blue CTA']: lazy(() => import('./assets/Section | Split Section | Future Proof Fiber - Blue CTA')),
  ['Section | Split Section | Gamers']: lazy(() => import('./assets/Section | Split Section | Gamers')),
  ['Section | Split Section | Gamers | P72']: lazy(() => import('./assets/Section | Split Section | Gamers | P72')),
  ['Section | Split Section | YTTV']: lazy(() => import('./assets/Section | Split Section | YTTV')),
  ['Section | Split Section | YTTV BOBO']: lazy(() => import('./assets/Section | Split Section | YTTV BOBO')),
  ['Section | Split Section | YTTV BOBO Summer Deal']: lazy(() => import('./assets/Section | Split Section | YTTV BOBO Summer Deal')),
  ['Section | Split Section | YTTV Sunday Ticket']: lazy(() => import('./assets/Section | Split Section | YTTV Sunday Ticket')),
  ['Section | Split Sections | P72']: lazy(() => import('./assets/Section | Split Sections | P72')),
  ['Section | Sticky CTA']: lazy(() => import('./assets/Section | Sticky CTA')),
  ['Section | Sticky CTA | GDT']: lazy(() => import('./assets/Section | Sticky CTA | GDT')),
  ['Section | Testimonials']: lazy(() => import('./assets/Section | Testimonials')),
  ['Section | Testimonials | Movers']: lazy(() => import('./assets/Section | Testimonials | Movers')),
  ['Section | Trophy Case | 3 Card - Editable']: lazy(() => import('./assets/Section | Trophy Case | 3 Card - Editable')),
  ['Section | Trophy Case | Editable']: lazy(() => import('./assets/Section | Trophy Case | Editable')),
  ['Section | VAS Cards | Editable']: lazy(() => import('./assets/Section | VAS Cards | Editable')),
  ['Section | VAS Cards | YTVV Summer Deal - Editable']: lazy(() => import('./assets/Section | VAS Cards | YTVV Summer Deal - Editable')),
  ['Section | Why Frontier']: lazy(() => import('./assets/Section | Why Frontier')),
  ['Sections | Fiber Features | Eero']: lazy(() => import('./assets/Sections | Fiber Features | Eero')),
};

export default listOfPreampAssets;
